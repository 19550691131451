import Step1 from "@/components/Step1";
import Step2 from "@/components/Step2";
import Button from "@/components/button/Button";
import { PhoneNumberUtil } from 'google-libphonenumber';
import { cn } from "@/utils/twMerge";
import { FormEvent, MouseEvent, useEffect, useRef, useState } from "react";
import { z } from "zod";

import Image from "next/image";

import Arrow from "@/public/svg/arrow-right.svg";
import submitFormDetails from "../../utils/services";

const currentMonth = new Date().toLocaleString("default", { month: "long" });

const step1Schema = {
    destination: z
        .string({
            required_error: "Please select a destination",
        })
        .min(1, {
            message: "Please select a destination",
        }),
    travelMonth: z
        .string({
            required_error: "Please select a travel month",
        })
        .min(1, {
            message: "Please select a travel month",
        }),
    duration: z
        .string({
            required_error: "Please select a duration",
        })
        .min(1, {
            message: "Please select a duration",
        }),
    persons: z
        .number({
            required_error: "Please select number of persons",
        })
        .min(1, {
            message: "Please select number of persons",
        })
        .max(10, {
            message: "Please select number of persons",
        }),
};

const step2Schema = {
    name: z.string().min(1, {
        message: "Please enter your name",
    }),
    email: z
        .string()
        .min(1, {
            message: "Please enter your email",
        })
        .email({
            message: "Please enter a valid email",
        }),
    whatsapp: z
        .string()
        .min(10, {
            message: "Please enter your whatsapp number",
        }),
    // whatsapp: z
    //     .string()
    //     .regex(/^\d{10}$/, {
    //         message: "Please enter a valid whatsapp number",
    //     })
    //     .min(1, {
    //         message: "Please enter your whatsapp number",
    //     }),
    language: z
        .string({
            required_error: "Please select a language",
        })
        .min(1, {
            message: "Please select a language",
        }),
};

const EnquiryWizard = ({ setFormSubmitStatus }: any) => {

    const initialState = {
        destination: "",
        travelMonth: currentMonth,
        duration: "",
        persons: 1,
        name: "",
        email: "",
        whatsapp: "",
        countryCode:"",
        enqId: new Date().getTime().toString(),
        pageUrl: getCurrentPageUrl(),
        language: "",
    };

    const [step, setStep] = useState(1);
    const [state, setState] = useState(initialState);
    const [errors, setErrors] = useState("");
    const phoneUtil = PhoneNumberUtil.getInstance();
    const handleNext = (e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        const schema = z.object(step1Schema);

        const result = schema.safeParse(state);
        if (!result.success) {
            setErrors(result.error.errors[0].message);
            return;
        }

        setErrors("");
        setStep(step + 1);
    };

    function getCurrentPageUrl() {
        if (typeof window !== 'undefined') {
            return window.location.href;
        }
        return '';
    }
    const isPhoneValid = () => {
        const countryCode=state.countryCode.toUpperCase();
        
        try {
          return phoneUtil.isValidNumber(phoneUtil.parse(state.whatsapp, countryCode));
          
        } catch (error) {            
          return false;
        }
      };
    const handleFormSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const schema = z.object(step2Schema);

        const result = schema.safeParse(state);

        if (!result.success) {
            setErrors(result.error.errors[0].message);
            return;
        }

        // Check WhatsApp number length
        
    if (state.whatsapp.length < 10) {
        setErrors("WhatsApp number must have at least 10 digits.");
        return;
    }
    if(!isPhoneValid()){
        setErrors("Please enter a valid number")
        return;
    }

        setErrors("");
        setStep(1);
        const submissionStatus = await submitFormDetails(state, "HomePage");
        setState(initialState);
        setFormSubmitStatus(submissionStatus);
        setTimeout(() => {
            setFormSubmitStatus(false);
        }, 40000);
    };

    const renderButton = () => {
        const isWhatsappValid = state.whatsapp.length >= 11;
        if (step === 2) {
            return (
                <Button
                    className="flex-center gap-3"
                    variant="orange"
                    type="submit"
                    disabled={!isWhatsappValid}
                >
                    <span>Submit</span>
                    <Image loading="lazy"
                        src={Arrow}
                        alt="Arrow"
                        width={24}
                        height={24}

                    />
                </Button>
            );
        }
        return (
            <Button
                className="flex-center text-center gap-3"
                variant="orange"
                type="button"
                onClick={handleNext}
            >
                <span>Next</span>
            </Button>
        );
    };

    const [isIntersecting, setIsIntersecting] = useState(true);
    const ref = useRef<HTMLFormElement>(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                setIsIntersecting(entry.isIntersecting);
            },
            { rootMargin: '300px' }
        );
        console.log(isIntersecting);
        observer.observe(ref.current!);

        return () => observer.disconnect();
    }, [isIntersecting]);

    const handleClick = (e: any) => {
        e.preventDefault();

        if (ref.current) {
            window.scrollTo({
                top: ref.current.offsetTop - 50,
                behavior: 'smooth'
            });
        }
    }

    return (
        <>
            <form ref={ref} name="hero-form" id="hero-form" onSubmit={handleFormSubmit}>
                <Step1
                    step={step}
                    state={state}
                    setState={setState}
                    showDestinationDropdown={true}
                />
                <Step2 step={step} state={state} setState={setState} />
                <div className="error-text text-sm font-medium">{errors}</div>
                <div className="flex flex-col mt-14">{renderButton()}</div>
            </form>
            <ul className="indicator flex-center gap-1">
                <li
                    className={cn("indicator-item", step === 1 ? "active" : "")}
                />
                <li
                    className={cn("indicator-item", step === 2 ? "active" : "")}
                />
            </ul>
            <div className={`${isIntersecting ? "hidden" : ""} md:hidden fixed w-screen bottom-0 w-100 flex justify-center items-center bg-white p-4 right-0 left-0 z-[1001]`}>
                <button className="button-green w-full">
                    <a
                        onClick={handleClick}
                        href=""
                        className="flex items-center justify-center gap-3"
                    >
                        <span className="text-lg">Send Enquiry</span>
                    </a>
                </button>
            </div>
        </>
    );
};

export default EnquiryWizard;
